import Vue from 'vue'
import App from './App'
import IconFont from '@/components/IconFont'
import '@/assets/css/main.css'

Vue.config.productionTip = false
Vue.use(IconFont)
new Vue({
  render: h => h(App)
}).$mount('#app')
