<template>
  <div id="app">
    <fbHeader :whiteBg="whiteBg" :tabIndex="0"></fbHeader>
    <div class="main-banner">
      <div class="banner-content">
        <div class="main-banner-title">
          <p>复变医疗科技</p>
          <p>让智能医疗创造无限价值</p>
          <div class="en">Fubian Medical Technology Co. enables intelligent medical care to create unlimited value</div>
        </div>
        <img v-if="mobile" class="mImg"  src="@/assets/image/app/index_banner.png" alt="index_banner">
        <img v-else src="@/assets/image/index/banner.png" alt="center1">
      </div>
    </div>
    <div class="main-center">
      <div class="main-center-box">
        <div class="centerBox">
          <img src="@/assets/image/index/center1.png" alt="center1">
          <span class="centerTitle">人工智能技术</span>
        </div>
        <div class="centerBox">
          <img src="@/assets/image/index/center2.png" alt="center2">
          <span class="centerTitle">专业研发团队</span>
        </div>
        <div class="centerBox">
          <img src="@/assets/image/index/center3.png" alt="center3">
          <span class="centerTitle">云端部署</span>
        </div>
        <div class="centerBox">
          <img src="@/assets/image/index/center4.png" alt="center4">
          <span class="centerTitle">专家医疗团队支持</span>
        </div>
      </div>
    </div>
    <div class="main-aikj">
      <div class="nav1-title">
        <span class="indexTitle">AI科技+医疗</span>
        <img src="@/assets/image/index/title1.png" alt="title1">
      </div>
      <div class="aikj-content">
        <div class="box">
          <img src="@/assets/image/index/ai1.png" alt="ai1">
          <div class="boxSpan">
            <h4>科研实力</h4>
            <span>联合哈佛大学医学院、国内知名院校、科研中心以及众多医学、人工智能领域教授、博士、硕士为医学科研赋能，在该学科前沿领域顶级期刊发表了多篇重要论文，具有自主创新能力与强核心科研竞争实力。</span>
          </div>
        </div>
        <div class="box">
          <img src="@/assets/image/index/ai2.png" alt="ai2">
          <div class="boxSpan">
            <h4>技术实力</h4>
            <span>具有国际领先的人工智能算法、图像、自然语言处理等技术硬实力。服务范围覆盖全球30多个国家和地区，多方位分析文字、语音、图像、视频等全数据类型。</span>
          </div>
        </div>
        <div class="box">
          <img src="@/assets/image/index/ai3.png" alt="ai3">
          <div class="boxSpan">
            <h4>应用落地</h4>
            <span>与国内多家医院、医学科研中心深入合作，重点解析中国临床医学在科技合作方面的进展，助力医学领域的数据采集标注、模型测试、临床试验全流程的技术加强及整体科研影响力的提升。</span>
          </div>
        </div>
      </div>
      <div class="aikj-bottom">
        <div class="box">
          <div class="boxTitle">
            <span class="indexTitle">我们致力于</span>
            <span class="eng">Our Vision</span>
          </div>
          <div class="boxTips">
            通过人工智能技术为国内外医疗服务机构提供创新的医疗信息化、智能化解决方案，为患者及医疗工作人员创造真实的价值
          </div>
          <div class="boxMain">
            <dl>
              <dt><img src="@/assets/image/index/yisheng.png" alt="yisheng"></dt>
              <dd>辅助医生</dd>
            </dl>
            <dl>
              <dt><img src="@/assets/image/index/huanzhe.png" alt="huanzhe"></dt>
              <dd>帮助患者</dd>
            </dl>
            <dl>
              <dt><img src="@/assets/image/index/yiyuan.png" alt="yiyuan"></dt>
              <dd>服务医院</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="main-chanpin">
      <div class="chanpin-main">
        <div class="chanpin-title">
          <span class="indexTitle otherTitle">产品方向</span>
        </div>
        <div class="chanpin-tips">
          以人工智能为基础的临床产品服务
        </div>
        <div class="chanpin-box">
          <div class="box">
            <img src="@/assets/image/index/yunyu.png" alt="yunyu">
            <h4>复变云愈</h4>
            <span class="tips">心理测评疏导系统</span>
            <span class="jieshao">针对心境障碍群体，帮助他们更好地认知自己的心理现状，集抑郁心理测评、自愈心理疏导等多功能一体的人工智能在线测评疏导软件。</span>
            <el-button class="btn" type="primary" size="normal" plain @click="lookDetail('/yunyu')">查看详情</el-button>
          </div>
          <div class="box">
            <img src="@/assets/image/index/kj.png" alt="kj">
            <h4>复变智识-膝关节辅诊</h4>
            <span class="tips">全膝关节置换术软组织平衡辅助决策系统</span>
            <span class="jieshao">通过对膝关节周边韧带及肌腱的MRI影像数据进行智能化分析处理，自动识别相关组织，辅助临床医生诊断，为膝关节相关病症提供分析依据与建议。</span>
            <el-button class="btn" type="primary" size="normal" plain @click="lookDetail('/knee')">查看详情</el-button>
          </div>
          <div class="box">
            <img src="@/assets/image/index/alz.png" alt="alz">
            <h4>复变念念</h4>
            <span class="tips">阿尔茨海默病辅助筛查系统</span>
            <span class="jieshao">结合语音识别技术、图像微表情识别、NLP自然语言理解、声音特征提取分析等人工智能技术，对患者面诊全过程进行多维度全面分析，帮助临床医生快速筛查轻度认知障碍的患者。</span>
            <el-button class="btn" type="primary" size="normal" plain @click="lookDetail('/alzheimer')">查看详情</el-button>
          </div>
          <div class="box">
            <img src="@/assets/image/index/zong.png" alt="zong">
            <h4>智能总检</h4>
            <span class="tips">总检智能分析系统</span>
            <span class="jieshao">应用自然语言处理技术，直接对原始体检数据进行分析，生成总检报告结论，直观展示疾病各级分类及风险，同时软件给出对应疾病和症状的建议，提高报告易读性，对被检者更友好。</span>
            <el-button class="btn" type="primary" size="normal" plain @click="lookDetail('/inspection')">查看详情</el-button>
          </div>
          <div class="box">
            <img src="@/assets/image/index/bingdu.png" alt="bingdu">
            <h4>复变智识-先心病筛查</h4>
            <span class="tips"></span>
            <span class="jieshao">结合先心病超声影像资源与人工智能和深度学习技术，研发人工智能辅助诊断系统，探索适合于基层医疗单位实际应用场景的先心病智能诊断平台。</span>
          </div>
          <div class="box" v-if="!mobile">
            <img src="@/assets/image/index/dian.png" alt="dian">
          </div>
        </div>
      </div>
    </div>
    <div class="main-partner">
        <div class="chanpin-title">
          <span class="indexTitle otherTitle">合作伙伴</span>
        </div>
        <div class="chanpin-tips">
          携手合作伙伴 共创医疗新生态
        </div>
      <div class="partners-wrap">
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/北京儿童医院.png" alt="北京儿童医院">
          <img v-else src="@/assets/image/partner/北京儿童医院1.png" alt="北京儿童医院1">
          <span>北京儿童医院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/上海仁济医院.png" alt="上海仁济医院">
          <img v-else src="@/assets/image/partner/上海仁济医院1.png" alt="上海仁济医院1">
          <span>上海仁济医院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/广东医科大学.png" alt="广东医科大学">
          <img v-else src="@/assets/image/partner/广东医科大学1.png" alt="广东医科大学1">
          <span>广东医科大学</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/广东医科大学附属医院.png" alt="广东医科大学附属医院">
          <img v-else src="@/assets/image/partner/广东医科大学附属医院1.png" alt="广东医科大学附属医院1">
          <span>广东医科大学附属医院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/首都医科大学.png" alt="首都医科大学">
          <img v-else src="@/assets/image/partner/首都医科大学1.png" alt="首都医科大学1">
          <span>首都医科大学</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/哈佛大学.png" alt="哈佛大学医学院">
          <img v-else src="@/assets/image/partner/哈佛大学1.png" alt="哈佛大学医学院1">
          <span>哈佛大学医学院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/伦敦国王.png" alt="伦敦国王学院医学院">
          <img v-else src="@/assets/image/partner/伦敦国王1.png" alt="伦敦国王学院医学院1">
          <span>伦敦国王学院医学院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/新疆中医医院.png" alt="新疆中医医院">
          <img v-else src="@/assets/image/partner/新疆中医医院1.png" alt="新疆中医医院1">
          <span>新疆中医医院</span>
        </div>
        <div class="every">
          <img v-if="!mobile" src="@/assets/image/partner/北京大学第六医院.png" alt="北京大学第六医院">
          <img v-else src="@/assets/image/partner/北京大学第六医院1.png" alt="北京大学第六医院1">
          <span>北京大学第六医院</span>
        </div>
      </div>
    </div>
    <fbFooter></fbFooter>
  </div>
</template>

<script>
import fbHeader from '@/components/fbHeader'
import fbFooter from '@/components/fbFooter'

export default {
  name: 'Home',
  components: {
    fbHeader,
    fbFooter
  },
  data () {
    return {
      whiteBg: false,
      mobile: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', () => this.scrollFn())
    this.mobile = window.matchMedia('(max-width: 768px)').matches
  },
  methods: {
    scrollFn () {
      const top = document.documentElement.scrollTop || document.body.scrollTop
      if (top > 60) {
        // 大于60黑色
        this.whiteBg = true
      } else {
        // 小于60白色
        this.whiteBg = false
      }
    },
    lookDetail (path) {
      window.location.href = path
    }
  }
}
</script>

<style lang="less">
@import "../../assets/css/main.less";
.main-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 520px;
  background: linear-gradient(360deg, #5790FF 0%, #17235F 100%);
  color: #FFFFFF;
  padding-top: @header-h;
  .banner-content{
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 650px;
      height: 450px;
    }
  }
  .main-banner-title {
    p {
      font-size: 40px;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      margin-bottom: 30px;
      letter-spacing: 4px;
      white-space: nowrap;
    }
    .en {
      width: 480px;
      font-size: 16px;
    }
  }
}
.main-center{
  width: 100%;
  height: 260px;
  background: #F8FBFF;
  padding: 50px 0;
  .main-center-box{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .centerBox{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 120px;
        height: 120px;
      }
      span{
        display: inline-block;
        height: 24px;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        color: #333333;
        letter-spacing: 1.5px;
        margin-top: 16px;
      }
    }
  }

}
.main-aikj{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  .aikj-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 56px;
    .box{
      width: 390px;
      height: 504px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      img{
        width: 390px;
        height: 250px;
      }
      .boxSpan{
        margin-top: 40px;
        padding: 0 30px;
        h4{
          height: 24px;
          line-height: 24px;
          font-size: 24px;
          color: #333333;
          text-align: center;
          font-weight: bold;
        }
        span{
          font-size: 14px;
          line-height: 20px;
          color: #888888;
          margin-top: 30px;
          display: inline-block;
        }
      }
    }
  }
  .aikj-bottom{
    margin-top: 100px;
    width: 100%;
    background: url(../../assets/image/index/yibg.png) no-repeat;
    background-size: cover;
    height: 576px;
    .box{
      width: 460px;
      height: 576px;
      background: rgba(255,255,255,0.69);
      padding: 54px 50px;
      .boxTitle{
        position: relative;
        .eng{
          position: absolute;
          height: 36px;
          font-size: 36px;
          font-weight: bold;
          line-height: 36px;
          color: #333333;
          opacity: 0.12;
          left: 90px;
          top: 15px;
          display: inline-block;
        }
      }
      .boxTips{
        margin-top: 50px;
        font-size: 16px;
        line-height: 28px;
        color: #333333;
      }
      .boxMain{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 36px;
        dl{
          width: 360px;
          height: 80px;
          background: #FFFFFF;
          padding: 12px 32px;
          display: flex;
          align-items: center;
          dt{
            img{
              width: 56px;
              height: 56px;
            }
          }
          dd{
            font-size: 24px;
            color: #333333;
            margin: 0;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
.main-chanpin{
    margin-top: 140px;
    width: 100%;
    background: url(../../assets/image/index/chanpinbg.png) no-repeat;
    background-size: cover;
    height: 1402px;
    .chanpin-main{
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding-top: 100px;
      .chanpin-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;
        .box{
          width: 390px;
          height: 500px;
          background: #FFFFFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 34px 40px;
          transition: transform 0.3s;
          img{
            width: 128px;
            height: 128px;
          }
          h4{
            margin-top: 24px;
            font-size: 24px;
            color: #333333;
            font-weight: bold;
            margin-bottom: 0;
          }
          .tips{
            margin-top: 24px;
            font-size: 16px;
            color: #333333;
          }
          .jieshao{
            font-size: 14px;
            margin-top: 30px;
            line-height: 26px;
            color: #888888;
          }
          .btn{
            margin-top: 24px;
            display: none;
            cursor: pointer;
          }
        }
        .box:nth-child(n+4){
          margin-top: 28px;
        }
        .box:last-child{
          justify-content: center;
          img{
            width: 200px;
            height: 36px;
          }
        }
        .box:hover{
          box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.12);
          transform: translate(0,-10px);
          .btn{
            display: block;
          }
        }
      }
    }
}
.title-wrap {
  .title-zh {
    font-size: 28px;
    font-weight: bold;
    color: #32BEC0;
  }
  .title-en {
    font-size: 20px;
    color: #999;
  }
}
.main-partner {
  padding-top: 120px;
  .partners-wrap {
    max-width: 1200px;
    margin: 0 auto;
    background: url(../../assets/image/index/panner.png) no-repeat;
    background-size: contain;
    width: 100%;
    height: 530px;
    margin-top: 95px;
    padding: 0 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .every {
      display: flex;
      flex-direction: column;
      align-items: center;
      span{
        color: #333;
        font-size: 14px;
        white-space: nowrap;
      }
      img {
        width: 110px;
        height: 110px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-banner {
    width: 100%;
    height: 31.25rem;
    background: linear-gradient(360deg, #5790FF 0%, #17235F 100%) !important;
    padding: 0 2.375rem;
    padding-top: 6.875rem;
    .banner-content{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .main-banner-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 2.25rem;
          letter-spacing: 0.25rem;
          white-space: nowrap;
          margin-bottom: 0;
        }
        .en {
          font-size: 0.75rem;
          width: 100%;
          text-align: center;
          margin: 1.25rem 0 1.875rem;
        }
      }
      .mImg{
        width: 19.375rem;
        height: 13.375rem;
      }
    }
  }
  .main-center{
    height: 19.375rem;
    padding: 1.25rem 1.875rem;
    .main-center-box{
      flex-wrap: wrap;
      .centerBox{
        width: 50%;
        img{
          width: 5rem;
          height: 5rem;
        }
        span{
          font-size: 1.125rem;
          margin-top: 0.625rem;
          white-space: nowrap;
        }
      }
      .centerBox:nth-child(n+3){
        margin-top: 1.5rem;
      }
    }
  }
  .main-aikj{
    padding-top: 2.5rem;
    .aikj-content{
      margin-top: 1.75rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .box{
        width: 21.4375rem;
        height: auto;
        img{
          width: 21.4375rem;
          height: 13.75rem;
          margin-left: -1px;
        }
        .boxSpan{
          padding: 1.5rem 1.25rem;
          margin-top: 0;
          h4{
            height: 1.5rem;
            line-height: 1.5rem;
            font-size: 1.125rem;
            margin: 0;
          }
          span{
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-top: 0.875rem;
          }
        }
      }
      .box:nth-child(n+1){
        margin-top: 1.25rem;
      }
    }

    .aikj-bottom{
      margin-top: 2.5rem;
      width: 100%;
      background: url(../../assets/image/app/index_doctor.png) no-repeat;
      background-size: cover;
      height: 17.6875rem;
      .box{
        width: 100%;
        height: auto;
        padding: 3rem 1.5625rem;
        background: transparent;
        .boxTitle{
          position: relative;
          .eng{
            position: absolute;
            height: 2.25rem;
            font-size: 2.25rem;
            font-weight: bold;
            line-height: 2.25rem;
            color: #333333;
            opacity: 0.12;
            left: 90px;
            top: 15px;
            display: inline-block;
          }
        }
        .boxTips{
          margin-top: 3.5rem;
          font-size: 0.875rem;
          line-height: 1.75rem;
          color: #333333;
        }
        .boxMain{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 2.25rem;
          dl{
            width: 15.0625rem;
            height: 3.25rem;
            padding: 0.25rem 2rem;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
            dt{
              img{
                width: 2.75rem;
                height: 2.75rem;
              }
            }
            dd{
              font-size:1rem;
              margin-left: 0.875rem;
            }
          }
        }
      }
    }
  }
  .main-chanpin{
    margin-top: 13.875rem;
    width: 100%;
    background: url(../../assets/image/app/index_product.jpg) no-repeat;
    background-size: cover;
    height: 158.375rem;
    .chanpin-main{
      padding-top: 2.5rem;
      .chanpin-box{
        width: 100%;
        margin-top: 1.75rem;
        justify-content: center;
        .box{
          width: 21.4375rem;
          height: auto;
          padding: 2.125rem 2.5rem;
          transition: transform 0.3s;
          img{
            width: 8rem;
            height: 8rem;
          }
          h4{
            margin-top: 18px;
            font-size: 18px;
          }
          .tips{
            margin-top: 10px;
            font-size: 14px;
          }
          .jieshao{
            font-size: 14px;
            margin-top: 18px;
            line-height: 28px;
          }
          .btn{
            margin-top: 24px;
            width: 16.4375rem;
            height: 2.5rem;
            display: block;
          }
        }
        .box:nth-child(n+2){
          margin-top: 1.75rem;
        }
        .box:last-child{
          img{
            width: 8rem;
            height: 8rem;
          }
        }
        .box:hover{
          box-shadow:none;
          transform: translate(0,0);
        }
      }
    }
  }
  .main-partner {
    padding: 2.5rem 0;
    .partners-wrap {
      background: url(../../assets/image/index/panner1.png) no-repeat;
      background-size: cover;
      height: 20rem;
      margin-top: 1.75rem;
      padding: 0 1.5rem;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      .every {
        margin-right: 0.875rem;
        margin-top: -3.75rem;
        span{
          display: none;
        }
        img {
          width: 3.375rem;
          height: 3.375rem;
        }
      }
      .every:nth-child(5),.every:nth-child(9){
        margin-right: 0;
      }
      .every:nth-child(n+6){
        margin-top: 0.875rem;
      }
    }
  }
}
</style>
